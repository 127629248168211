import { inject, Injectable } from "@angular/core";
import { AnalyticsService } from "@platform-app/app/core/analytics/analytics.service";
import { Store } from "@ngxs/store";
import { InvitePartnerFlowEvent } from "@platform-app/app/core/analytics/events";
import { firstValueFrom } from "rxjs";
import { StaticDataState } from "@platform-app/app/main/shared/state/static-data.state";
import {
  AffiliationAgreement,
  AffiliationAgreementSource,
  ExternalPartnershipType,
} from "@platform-app/app/core/api/models";
import { EnsureDisciplinesLoaded } from "@platform-app/app/main/shared/state/static-data.actions";

@Injectable({
  providedIn: "root",
})
export class InvitePartnerTrackingService {
  private readonly analyticsService = inject(AnalyticsService);
  private readonly store = inject(Store);

  async trackPartnerInvitation(
    agreement: AffiliationAgreement,
    partnerIsRegistered: boolean,
    affiliationAgreementSource: AffiliationAgreementSource = AffiliationAgreementSource.Upload,
  ) {
    await firstValueFrom(this.store.dispatch(new EnsureDisciplinesLoaded()));
    const disciplines = this.store.selectSnapshot(StaticDataState.disciplines);

    this.analyticsService.track(
      new InvitePartnerFlowEvent({
        agreementType:
          affiliationAgreementSource === AffiliationAgreementSource.Upload
            ? "Existing Agreement"
            : "Agreement Builder",
        agreementName: agreement.name,
        agreementDisciplinesIncluded: agreement.disciplinesIds.map(
          (id) => disciplines.find((d) => d.id === id)!.name,
        ),
        agreementStartDate: agreement.startDate!,
        agreementEndDate: agreement.endDate,
        noEndDate: agreement.noEndDate,
        partnerType:
          agreement.externalPartnershipType === ExternalPartnershipType.Existing
            ? "existing"
            : "new",
        partnerIsRegistered,
      }),
    );
  }
}
